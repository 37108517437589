<template>
    <b-card>
        <div title="Si l'import est fini">
            <p> Seulement si vous avez reçu l'email de finalization d'import.</p>
            <p> Vous pouvez preceder </p>
            <b-row>
                <b-col cols="12" class="d-flex align-items-stretch mt-2">
                    <b-btn variant="primary" @click="copyData">
                        <feather-icon icon="UploadIcon" />
                        Copier les données
                    </b-btn>
                </b-col>
            </b-row>
        </div>
    </b-card>
</template>


<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    props: {
        marque: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            area: null,
        };
    },
    computed: {
        ...mapGetters(['app/successToast', 'app/errorToast']),
    },
    methods: {
        ...mapActions({
                copyImport: 'app/copyImport'
            }),
        async copyData() {
            try {
                let brand = this.marque ;
                console.log("brand", brand)
                const res = await this.copyImport(brand);
                console.log('Response:', res);
            } catch (e) {
                this.$toast(this.errorToast('Erreur'));
            } finally {
                this.$root.toggleGlobalLoading(false);
            }
        },
    },
};
</script>


<style></style>
