<template>
    <b-card>
        <div title="Importer Fichier">
            <p>Vous êtes sur le point de mettre à jour la marque "{{ marque }}".</p>
            <p>Si vous le souhaitez, changez de marque</p>
            <b-row>
                <b-col class="d-flex align-items-stretch">
                    <b-button class="mt-2" variant="primary" @click="openFilePoi">
                        <feather-icon icon="UploadIcon" />
                        Importer fichier POI
                    </b-button>
                    <b-form-file ref="filePoi" v-model="poi" class="d-none" accept=".csv"></b-form-file>
                    <b-button class="mt-2 ml-2" variant="primary" @click="openFileArea">
                        <feather-icon icon="UploadIcon" />
                        Importer fichier POI_AREAS
                    </b-button>
                    <b-form-file ref="fileArea" v-model="area" class="d-none" accept=".csv"></b-form-file>
                </b-col>
            </b-row>
            <h5 class="mt-3">Veuillez écrire votre message de commit</h5>
            <b-input-group class="mb-1 w-25 mt-2">
                <b-input v-model="commit" placeholder="Message de commit"></b-input>
            </b-input-group>
            <div class="d-flex justify-content-between mt-3">
                <b-button variant="primary" @click="pushFile(poi, area)"> Confirmer </b-button>
            </div>
        </div>
    </b-card>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        props: {
            marque: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                poi: null,
                area: null,
                missing: [],
                commit: '',
            };
        },
        computed: {
            ...mapGetters({
                successToast: 'app/successToast',
                errorToast: 'app/errorToast',
            }),
        },
        watch: {},
        methods: {
            ...mapActions({
                addImportData: 'app/addImportData',
            }),
            init() {
                this.$root.toggleGlobalLoading(false);
                let promises = [this.fetchBrands()];
                Promise.all(promises)
                    .then((res) => {
                        this.$root.toggleGlobalLoading(false);
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$toast(this.errorToast('Erreur lors de la récupération des données'));
                    });
            },
            fetchBrands() {
                this.$set(this, 'marque', this.selectedBrand);
                return this.getBrands().then((res) => {
                    const brands = res.data.map((b) => b.name);
                    this.$set(this, 'brands', brands);
                });
            },
            pushFile(poi, area) {
                this.missing = [];
                let formData = new FormData();
                if (poi) {
                    formData.append('poi', poi);
                }
                if (area) {
                    formData.append('area', area);
                }
                formData.append('msg', this.commit);
                formData.append('email', localStorage.getItem('userEmail'));
                let payload = {
                    form: formData,
                    brand: this.marque,
                };
                this.$root.toggleGlobalLoading(true);
                this.$store
                    .dispatch('app/addImportData', payload)
                    .then((res) => {
                        this.$toast(this.successToast(res.data));
                    })
                    .catch((e) => {
                        this.errors = e.response.data.error;
                        this.$toast(this.errorToast('Erreur'));
                    })
                    .finally((e) => {
                        this.$root.toggleGlobalLoading(false);
                    });
            },
            openFilePoi() {
                this.$refs.filePoi.$refs.input.click();
            },
            openFileArea() {
                this.$refs.fileArea.$refs.input.click();
            },
        },
    };
</script>

<style></style>
