<template>
    <b-card>
        <div title="Télécharger Fichier">
            <p>Vous êtes sur le point de mettre à jour la marque "{{ marque }}".</p>
            <p>Si vous le souhaitez, changez de marque.</p>
            <b-col cols="10" v-if="errors" class="mt-2">
                <div class="table thead th">Result :</div>
                <div class="nav-tabs">{{ errors }}</div>
            </b-col>
            <b-row>
                <b-col cols="12" class="d-flex align-items-stretch mt-2">
                    <b-btn variant="primary" @click="truncate">
                        <feather-icon icon="UploadIcon" />
                        Truncate BD
                    </b-btn>
                    <b-btn style="margin-left:10px;"   variant="primary" @click="$refs['modal-run-script'].show()">
                        <feather-icon icon="UploadIcon" />
                        Exécuter le Script
                    </b-btn>
                </b-col>
            </b-row>
        </div>
        <b-modal ref="modal-run-script" hide-footer title="Import Data">
            <p>Enregistrez votre email pour être notifié en cas d'erreur ou à la fin de l'exécution du script.</p>
            <b-input v-model="email" class="mb-2"></b-input>
            <b-row>
                <b-col cols="12" class="d-flex align-items-stretch">
                    <b-btn variant="primary" @click="runScript">
                        <feather-icon icon="UploadIcon" />
                        Exécuter le Script
                    </b-btn>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-between mt-3">
                <b-button @click="cancel()">Annuler</b-button>
                <b-button variant="primary" @click="runScript">Confirmer</b-button>
            </div>
        </b-modal>
    </b-card>
</template>


<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    props: {
        marque: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            area: null,
            errors: '',
            email: '',
        };
    },
    computed: {
        ...mapGetters(['app/successToast', 'app/errorToast']),
    },
    methods: {
        ...mapActions({
                runImportScript: 'app/runImportScript',
                truncateTables: 'app/truncateTables',
            }),
        cancel() {
            this.$refs['modal-run-script'].hide();
        },
        async truncate() {
            try {
                let payload = { brand: this.marque };
                const res = await this.truncateTables(payload);
                console.log('Response:', res);
            } catch (e) {
                this.errors = e.response.data;
                this.$toast(this.errorToast('Erreur'));
            } finally {
                this.$root.toggleGlobalLoading(false);
            }
        },
        async runScript() {
            try {
                let payload = { brand: this.marque, email: this.email };
                const res = await this.runImportScript(payload);
                console.log('Response:', res);
                this.$refs['modal-run-script'].hide();
            } catch (e) {
                this.errors = e.response.data;
                this.$toast(this.errorToast('Erreur'));
                this.$refs['modal-run-script'].hide();
            } finally {
                this.$root.toggleGlobalLoading(false);
            }
        },
    },
};
</script>


<style></style>
