<template>
    <b-card>
        <b-input-group class="mb-1 w-25 d-flex ml-auto">
            <b-input-group-prepend>
                <b-input-group-text>
                    <feather-icon icon="SearchIcon"></feather-icon>
                </b-input-group-text>
            </b-input-group-prepend>
            <b-input v-model="filter"></b-input>
        </b-input-group>

        <b-table hover striped responsive ref="table" :filter="filter" :items="codeForTable" :fields="fields" :per-page="perPage" :current-page="currentPage" @filtered="onTableFiltered"> </b-table>
        <b-pagination class="mt-3" v-model="currentPage" :total-rows="rows" :per-page="perPage" first-number></b-pagination>
    </b-card>
</template>

<script>
    import { mapGetters } from 'vuex';
    export default {
        props: {
            posInfo: {
                type: Array,
                required: true,
            },
            marque: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                zipcode: '',
                selectedPos: {},
                modalShow: false,
                fields: [
                    { key: 'point_code', label: 'Code point de vente' },
                    { key: 'name', label: 'Nom' },
                    { key: 'city', label: 'Nom de la ville' },
                    { key: 'address', label: 'Adresse' },
                    { key: 'presence', label: 'Presence' },
                ],
                rows: 0,
                perPage: 10,
                currentPage: 1,
                filter: '',
            };
        },
        computed: {
            ...mapGetters({
                successToast: 'app/successToast',
                errorToast: 'app/errorToast',
            }),
            codeForTable() {
                let data = this.posInfo.map((pos) => {
                    const name = pos.name || (pos.details && pos.details.name);
                    const city = pos.city || (pos.details && pos.details.city);
                    const address = pos.address || (pos.details && pos.details.address);
                    const presence = pos.isPresent === 'onlyImport' ? 'seulement dans le fichier' : 'seulement dans la base de données';
                    return {
                        ...pos,
                        name,
                        city,
                        address,
                        presence,
                    };
                });

                if (this.filter === '') this.setRows(data);
                return data;
            },
        },
        watch: {},
        methods: {
            setRows(list) {
                this.rows = list.length;
            },
            onTableFiltered(list) {
                this.setRows(list);
            },
        },
    };
</script>

<style></style>
